import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery'
import PropTypes from 'prop-types'

import { KeyboardArrowLeft } from '@styled-icons/material/KeyboardArrowLeft'
import { KeyboardArrowRight } from '@styled-icons/material/KeyboardArrowRight'

const propTypes = {
  reference: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

const renderLeftNav = (onClick, disabled) => (
  <button
    type='button'
    className='image-gallery-icon image-gallery-left-nav'
    disabled={disabled}
    onClick={onClick}
    aria-label='Previous Slide'
  >
    <KeyboardArrowLeft size='18' />
  </button>
)

const renderRightNav = (onClick, disabled) => (
  <button
    type='button'
    className='image-gallery-icon image-gallery-right-nav'
    disabled={disabled}
    onClick={onClick}
    aria-label='Next Slide'
  >
    <KeyboardArrowRight size='18' />
  </button>
)

const ResultHeader = ({ reference, product }) => {
  const searchParams = new URLSearchParams(location.search)
  const referenceName = searchParams.get('reference')
  const lotNumber = searchParams.get('lot')

  const headerText = lotNumber
    ? `${referenceName} - ${lotNumber}`
    : `${referenceName}`

  return (
    <Card.Header>
      <Row>
        <Col lg={8}>
          <p className='typewriter'>{headerText}</p>
          <h4>
            <strong>{product.name}</strong>
          </h4>
        </Col>
        <Col className='product-slideshow'>
          {!!product.images.length && (
            <ImageGallery
              items={product.images}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              showBullets={product.images.length > 1}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
              showNav={false}
              autoPlay={true}
            />
          )}
        </Col>
      </Row>
    </Card.Header>
  )
}

ResultHeader.propTypes = propTypes

export default ResultHeader
