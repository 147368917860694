import React from 'react'
import styled from 'styled-components'
import { Row } from 'react-bootstrap'
import languages from '@cospired/i18n-iso-languages'
import countries from 'i18n-iso-countries'
import _ from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Title, Description, Tag, CalendarToday, Flag, Language } from '@styled-icons/material'

import { toBoolean } from 'components/shared/utils'
import states from '../helpers/States'

const propTypes = {
  reference: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
}

languages.registerLocale(require('@cospired/i18n-iso-languages/langs/en.json'))

const USER_DATE_FORMAT = 'MMM DD, YYYY'

const circleStyle = `
  width: 54px;
  height: 54px;
  border: 1px solid;
  border-radius: 50%;
  background-color: white;
  padding: 15px;
`
const CircleType = styled(Title)`
  ${circleStyle}
`
const CircleFileText = styled(Description)`
  ${circleStyle}
`
const CircleHash = styled(Tag)`
  ${circleStyle}
`
const CircleCalendar = styled(CalendarToday)`
  ${circleStyle}
`
const CircleFlag = styled(Flag)`
  ${circleStyle}
`
const CircleGlobe = styled(Language)`
  ${circleStyle}
`

const OrderDetail = ({ reference, document, product, theme, t, i18n }) => {
  const mergeEU = toBoolean(_.get(theme, 'layout.merge_eu', 'false'))
  const getLanguageName = (languageCode) =>
    languages.getName(languageCode, 'en')
  const locale = i18n.language.slice(0, 2)
  const translateCountryCode = (CC) => {
    const CCwithEU = _.includes(states, CC) && mergeEU ? 'EU' : CC
    return countries.getName(CCwithEU, 'en')
  }

  const renderAttributeRow = (index, label, title, value, Icon) => (
    <Row className='mb-3' key={index}>
      <div className='col-md-2'>
        <Icon />
      </div>
      <div
        className='col-md-10'
        data-testid={title.toLowerCase().replace(' ', '-')}
      >
        <p className='mb-0'>
          <b>{label}:</b>
        </p>
        <p>{value}</p>
      </div>
    </Row>
  )

  const contentCountries = toBoolean(_.get(theme, 'content_countries', 'false'))

  const documentLanguages = document
    ? document.languages.map((d) => getLanguageName(d)).join(', ')
    : ''
  const documentValidFrom =
    document && moment(document.versions[0].valid_from).format(USER_DATE_FORMAT)
  const documentCountries = document
    ? _.uniq(document.countries.map((c) => translateCountryCode(c))).join(', ')
    : ''

  const translateContentSetTitle = (document) =>
    document.document_type_translations[locale] || document.document_type

  const documentAttributes = [
    [t('order_detail.product_name'), 'product-name', product.name, CircleType],
    [
      t('order_detail.document_type'),
      'document-type',
      translateContentSetTitle(document),
      CircleFileText
    ],
    [t('order_detail.reference'), 'reference', reference.name, CircleHash],
    [
      t('order_detail.valid_from'),
      'valid-from',
      documentValidFrom,
      CircleCalendar
    ],
    !contentCountries && [t('order_detail.countries'), 'countries', documentCountries, CircleFlag],
    [t('order_detail.languages'), 'languages', documentLanguages, CircleGlobe]
  ].filter(Boolean)

  return (
    <div data-testid='order-detail'>
      <h5 className='mb-5' data-testid='document-details'>
        {t('order_detail.document_details')}
      </h5>
      {documentAttributes.map((da, index) => renderAttributeRow(index, ...da))}
    </div>
  )
}

OrderDetail.propTypes = propTypes

const mapStateToProps = ({ theme }) => ({ theme })

export default connect(mapStateToProps, null)(withTranslation()(OrderDetail))
