import React from 'react'
import PropTypes from 'prop-types'
import { isIE, isMobile, isFirefox, browserVersion } from 'react-device-detect'

import { withSize } from 'react-sizeme'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

const propTypes = {
  size: PropTypes.object.isRequired,
  documentSignedURL: PropTypes.string
}

const ASPECT_RATIO = 1.414

const StyledDiv = styled.div`
  height: ${({ size }) => size.width * ASPECT_RATIO}px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`

const DocumentViewer = ({ size, documentSignedURL }) => {
  const filePath = new URL(documentSignedURL, window.location.origin).href
  const isOldFirefox = isFirefox && parseInt(browserVersion) <= 75
  console.log({ isIE, isMobile, isFirefox, browserVersion, isOldFirefox })

  if (isIE || isOldFirefox) {
    return (
      <p data-testid='document-viewer'>
        <Trans i18nKey={'document_viewer.outdated'}>
          Your browser is outdated and can not support this view. You can
          <a
            download='document.pdf'
            data-testid='document-download'
            className='btn btn-link pl-0 action-btn outdated-download-link'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => window.open(filePath)}
          >
            download the document.
          </a>
        </Trans>
      </p>
    )
  } else if (isMobile) {
    return (
      <p data-testid='document-viewer'>
        <Trans i18nKey={'document_viewer.mobile'}>
          Preview feature is not available for mobile devices. Please download
          the file directly by clicking the <b>Download</b> button on the menu.
        </Trans>
      </p>
    )
  } else {
    return (
      <StyledDiv
        data-testid='document-viewer'
        className='document-viewer'
        size={size}
      >
        <iframe
          src={filePath}
          type='application/pdf'
          width='100%'
          height={size.width * ASPECT_RATIO}
          data-testid='iframe-document-viewer'
        />
      </StyledDiv>
    )
  }
}

DocumentViewer.propTypes = propTypes

export default withSize()(DocumentViewer)
