import i18n from 'i18next'
import axios from '../helpers/Api';
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'

import { SUPPORTED_LANGUAGES } from 'components/connect/helpers/Constants'
import regionsTranslations from 'components/connect/helpers/i18n/regionsTranslations'

const detectionOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path'
  ],
  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
  // only detect languages that are in the whitelist
  checkWhitelist: true
}

let checkedForPreSignedUrl = false
let preSignedUrl = null

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // wire LanguageDetector to i18n
  .init(
    {
      keySeparator: '.', // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      detection: detectionOptions,
      debug: false,
      fallbackLng: 'en',
      supportedLngs: SUPPORTED_LANGUAGES,
      preload: process.env.NODE_ENV !== 'production' ? ['en', 'fr'] : SUPPORTED_LANGUAGES,
      ns: ['translation'],
      defaultNS: 'translation',
      backend: {
        backends: [
          HttpBackend,
          HttpBackend
        ],
        backendOptions: [{
          loadPath: async () => {
            if (!checkedForPreSignedUrl) {
              const response = await axios.get('/themes.json')
              preSignedUrl = response.data.sign_translations_url
              checkedForPreSignedUrl = true
            }
            return preSignedUrl
          },
          parse: (data, lng, ns) => JSON.parse(data)[lng][ns]
        }, {
          loadPath: '/translations.json',
          parse: (data, lng, ns) => JSON.parse(data)[lng][ns]
        }]
      },
      react: {
        useSuspense: false,
      }
    },
    () => {
      // this callback will overide detector to remove region from language
      const lang = localStorage.getItem('i18nextLng').slice(0, 2)
      localStorage.removeItem('i18nextLng')
      localStorage.setItem('i18nextLng', lang)
      // backend loaders come first and only on this callback we add continents and countries translations
      SUPPORTED_LANGUAGES.forEach((language) =>
        i18n.addResourceBundle(
          language,
          'translation',
          regionsTranslations[language].translation,
          false,
          false
        )
      )
    }
  )

export default i18n
