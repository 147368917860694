import browserUpdate from 'browser-update'

browserUpdate({
  required: {
    c: -3,
    f: -3,
    s: -3,
    ios: -3,
    e: -3,
    i: 12,
    o: -3,
    o_a: -3,
    y: -3,
    v: -3,
    samsung: -3
  },
  insecure: true,
  noclose: true
})
