import { SET_REFERENCE } from './../constants'

const initialState = null

export function referenceReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_REFERENCE:
      return payload.reference
    default:
      return state
  }
}

export default referenceReducer
