import React from 'react'
import { Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Theme } from 'interfaces/Theme'

const propTypes = {
  theme: Theme,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  t: PropTypes.func.isRequired
}

const InfoModal = ({ theme, show, handleClose, t }) => {
  const company = theme.organisation_name
  return (
    <Modal
      show={show}
      onHide={handleClose}
      data-testid='label-overlay'
      centered
      dialogClassName='connect-dialog'
      className='info-modal'
      backdropClassName='info-modal-backdrop'
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('about.heading', { company })}</Modal.Title>
      </Modal.Header>

      <Modal.Body data-testid='label-overlay-body'>
        {' '}
        <div dangerouslySetInnerHTML={{ __html: t('about.paragraph') }} />
      </Modal.Body>
    </Modal>
  )
}

InfoModal.propTypes = propTypes

export default withTranslation()(InfoModal)
