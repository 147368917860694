import { SET_COUNTRY } from './../constants'

const initialState = null

export function locationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_COUNTRY:
      return { country: payload.country }
    default:
      return state
  }
}

export default locationReducer
