import React from 'react'
import { withCookies } from 'react-cookie'
import PropTypes from 'prop-types'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  language: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string
  })
}

const LanguageItem = ({ onClick, language }) => (
  <div>
    <p
      data-testid='language-name'
      onClick={() => onClick(language.code)}
      className='language-item'
    >
      {language.name}
    </p>
  </div>
)

LanguageItem.propTypes = propTypes

export default withCookies(LanguageItem)
