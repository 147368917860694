import React from 'react'
import ResultHeader from './ResultHeader'
import ResultContent from './ResultContent'
import PropTypes from 'prop-types'

const propTypes = {
  reference: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  userLocation: PropTypes.object.isRequired
}

const ResultCard = ({ reference, product, userLocation }) => {
  return (
    <div className='connect-card container-md'>
      <ResultHeader reference={reference} product={product} />
      <ResultContent
        reference={reference}
        product={product}
        userLocation={userLocation}
      />
    </div>
  )
}

ResultCard.propTypes = propTypes

export default ResultCard
