import React from 'react'
import isoCountries from 'i18n-iso-countries'
import { sortBy } from 'lodash'
import PropTypes from 'prop-types'

import CountryListItem from 'components/connect/region/CountryListItem'

const propTypes = {
  onSelect: PropTypes.func,
  countries: PropTypes.array,
  locale: PropTypes.string
}

const CountryList = ({ onSelect, countries, locale }) => {
  const getSortedCountryNames = () => {
    const translatedCountries = countries.map((countryCode) => {
      return {
        code: countryCode,
        name: isoCountries.getName(countryCode, locale)
      }
    })
    return sortBy(translatedCountries, ['name'])
  }

  return (
    <div data-testid='country-list' className='country-list'>
      {getSortedCountryNames().map((country) => (
        <CountryListItem
          key={country.code}
          onSelect={onSelect}
          country={country}
        />
      ))}
    </div>
  )
}

CountryList.propTypes = propTypes

export default CountryList
