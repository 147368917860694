import React from 'react'
import { Row, Col, Tab, Nav, Card } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Theme } from 'interfaces/Theme'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Cookies } from 'react-cookie'

import DocumentsByCountry from './DocumentsByCountry'
import DocumentsByLanguage from './DocumentsByLanguage'
import { SELECTED_COUNTRY_CODE_COOKIE_NAME } from 'components/connect/helpers/Constants'

const propTypes = {
  theme: Theme,
  reference: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  userLocation: PropTypes.object
}

const DEFAULT_LOCALE = 'en'

const ResultContent = ({ reference, product, userLocation, theme }) => {
  const cookies = new Cookies()
  const cookieValue = cookies.get(SELECTED_COUNTRY_CODE_COOKIE_NAME)
  const groupedDocuments = _.groupBy(product.documents, 'content_set_id')
  const { t, i18n } = useTranslation()
  const locale = i18n.language.slice(0, 2)

  const groupedAndSortedDocuments = theme.visible_content_sets.reduce((acc, cs) => {
    return groupedDocuments[cs] ? { ...acc, [cs]: groupedDocuments[cs] } : acc;
  }, {});

  const translateContentSetTitle = (documents) =>
    documents[0].document_type_translations[locale] ||
    documents[0].document_type_translations[DEFAULT_LOCALE]

  const getDefaultActiveKey = () => {
    const { visible_content_sets: activeSets } = theme

    const visibleDocs = theme.country_selection
      ? product.documents.filter(doc => doc.countries.includes(cookieValue))
      : product.documents

    const visibleSets = visibleDocs
      .map(doc => doc.content_set_id)
      .filter(setId => activeSets.includes(setId))
      .sort((a, b) => activeSets.indexOf(a) - activeSets.indexOf(b))

    return Array.from(new Set(visibleSets))[0];
  }

  const tabNav = _.map(groupedAndSortedDocuments, (documents, key) => {
    if (!documents) return

    const filteredDocuments = documents.filter(doc => doc.countries.includes(cookieValue))

    if (!theme.country_selection || filteredDocuments.length) {
      return (
        <Nav.Item key={key}>
          <Nav.Link eventKey={key} data-testid='content-set-title'>
            {translateContentSetTitle(documents, key)}
          </Nav.Link>
        </Nav.Item>
      )
    }
  })

  const tabCountryContent = _.map(
    groupedAndSortedDocuments,
    (documents, key) =>
      documents && (
        <Tab.Pane key={key} eventKey={key}>
          <DocumentsByCountry
            documents={documents}
            product={product}
            reference={reference}
            country={userLocation && userLocation.country}
          />
        </Tab.Pane>
      )
  )

  const renderNoContent = () => (
    <Row className='justify-content-md-center'>
      <Col md='auto' className='mt-3'>
        <span className='text-secondary-dark'>{t('results.no_content')}</span>
      </Col>
    </Row>
  )

  const tabLanguageContent = _.map(
    groupedAndSortedDocuments,
    (documents, key) => {
      if (!documents) {
        return
      }
      const filteredDocuments = documents.filter((document) =>
        document.countries.includes(cookieValue)
      )
      if (filteredDocuments.length === 0) {
        return (
          <Tab.Pane key={key} eventKey={key}>
            <Row className='justify-content-md-center'>
              <Col md='auto' className='mt-3'>
                <span className='text-secondary-dark'>
                  {t('results.no_content')}
                </span>
              </Col>
            </Row>
          </Tab.Pane>
        )
      }
      return (
        <Tab.Pane key={key} eventKey={key}>
          <Card data-testid='accordion-card' className='filtered-language-card'>
            <Card.Body data-testid='accordion-card-body'>
              <DocumentsByLanguage
                reference={reference}
                documents={filteredDocuments}
                product={product}
                locale={locale}
              />
            </Card.Body>
          </Card>
        </Tab.Pane>
      )
    }
  )

  const visibleDocuments = product.documents.filter(doc => doc.countries.includes(cookieValue)).length;

  return (
    <Tab.Container defaultActiveKey={getDefaultActiveKey(groupedAndSortedDocuments)}>
      <Row className='tab-nav'>
        <Col>
          <Nav>{tabNav}</Nav>
        </Col>
      </Row>
      <Row className='tab-content-container'>
        <Col>
          <Tab.Content data-testid='tab-content'>
            {!theme.country_selection ? tabCountryContent : tabLanguageContent}
            {!theme.visible_content_sets.length && renderNoContent()}
            {(theme.country_selection && !visibleDocuments) && renderNoContent()}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

ResultContent.propTypes = propTypes

const mapStateToProps = ({ theme }) => ({ theme })

export default connect(mapStateToProps, null)(ResultContent)
