import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'

const propTypes = {
  reference: PropTypes.object,
  t: PropTypes.func
}

const ErrorView = ({ reference, t }) => {
  const referenceName = reference?.name ? `(${reference?.name})` : ''

  return (
    <div
      data-testid='ErrorView'
      className='d-flex align-items-center justify-content-center flex-grow-1'
    >
      <Row>
        <Col>
          <h1>{t('error_view.heading')}</h1>
          <p>
            <Trans i18nKey='error_view.body' referenceName={referenceName}>
              The reference you entered <code>{{ referenceName }}</code> does not seem to exist.
            </Trans>
          </p>
          <p>To search for another reference please go to the home page.</p>
          <Button as={Link} to='/'>
            {t('error_view.button')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

ErrorView.propTypes = propTypes

export default withTranslation()(ErrorView)
