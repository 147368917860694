import React, { useState } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { isIE } from 'react-device-detect'
import _ from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'

const propTypes = {
  notifications: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

const DocumentModalFooter = ({ notifications, onHide, t }) => {
  const [agreed, setAgreed] = useState(false)
  const handleAgreed = () => setAgreed(!agreed)
  const haveWarningNotification = !_.isEmpty(
    notifications.filter((n) => n.category === 'warning')
  )
  const label = haveWarningNotification
    ? t('notification_footer.proceed')
    : t('notification_footer.ok')

  return (
    <Modal.Footer
      data-testid='notification-footer'
      className={cx({ 'modal-footer-ie': isIE })}
    >
      <Row>
        <Col lg={10}>
          {haveWarningNotification && (
            <Form.Group data-testid='notification-footer-form'>
              <Form.Check
                type='checkbox'
                label={t('notification_footer.statement')}
                onClick={handleAgreed}
                id='notificationAgreedCheckbox'
                data-testid='notification-footer-checkbox'
              />
            </Form.Group>
          )}
        </Col>

        <Col lg={2}>
          <Button
            variant='primary'
            disabled={!agreed && haveWarningNotification}
            onClick={onHide}
            data-testid='notification-button'
          >
            {label}
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  )
}

DocumentModalFooter.propTypes = propTypes

export default withTranslation()(DocumentModalFooter)
