import React from 'react'
import { Modal } from 'react-bootstrap'
import { Info, Warning } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const propTypes = {
  notifications: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
}

const NotificationHeader = ({ notifications, t }) => {
  const renderTitleContent = () => {
    if (notifications.length === 1) {
      return (
        <>
          {notifications[0].category === 'info' ? (
            <Info size='20' data-testid='notification-header-icon' />
          ) : (
            <Warning
              size='20'
              className='alert-triangle'
              data-testid='notification-header-icon'
            />
          )}
          <h5>{notifications[0].title}</h5>
        </>
      )
    }
    return <h5>{t('notification_header.notification').toUpperCase()}</h5>
  }

  return (
    <Modal.Header
      className='notification-header'
      data-testid='notification-header'
    >
      {renderTitleContent()}
    </Modal.Header>
  )
}

NotificationHeader.propTypes = propTypes

export default withTranslation()(NotificationHeader)
