import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { KeyboardArrowUp } from '@styled-icons/material/KeyboardArrowUp'
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Region } from 'interfaces/Region'

import CountryList from './CountryList'

const regionHeaderPropTypes = {
  name: PropTypes.string,
  isOpen: PropTypes.bool
}

const RegionHeader = ({ name, isOpen }) => {
  return (
    <div>
      <span className='region-card-name'>{name}</span>
      <span
        className={cx('float-right', isOpen ? 'arrow-up' : 'arrow-down')}
        data-testid='region-card-header-arrow'
      >
        {isOpen ? (
          <KeyboardArrowUp size='28' />
        ) : (
          <KeyboardArrowDown size='28' />
        )}
      </span>
    </div>
  )
}

RegionHeader.propTypes = regionHeaderPropTypes

const propTypes = {
  onSelect: PropTypes.func,
  region: Region,
  locale: PropTypes.string,
  activeRegion: PropTypes.string,
  setActiveRegion: PropTypes.func
}

const RegionCard = ({
  onSelect,
  region,
  locale,
  activeRegion,
  setActiveRegion
}) => {
  const isOpen = activeRegion === region.code
  const { t } = useTranslation()
  const toggleCollapse = () => {
    setActiveRegion(isOpen ? null : region.code)
  }

  return (
    <Card className={cx('region-card mx-auto', isOpen && 'active')}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={region.code}
        onClick={toggleCollapse}
        data-testid='region-card-header'
      >
        <RegionHeader name={t(`continents.${region.code}`)} isOpen={isOpen} />
      </Accordion.Toggle>
      <Accordion.Collapse
        eventKey={region.code}
        data-testid='region-card-collapse'
      >
        <Card.Body data-testid='region-card-body'>
          <CountryList
            onSelect={onSelect}
            countries={region.countries}
            locale={locale}
          />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

RegionCard.propTypes = propTypes

export default RegionCard
