import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import countries from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Theme } from 'interfaces/Theme'

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  userLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  locale: PropTypes.string,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  theme: Theme
}

const CountryConfirm = ({ onConfirm, userLocation, locale, show, theme }) => {
  const { t } = useTranslation()
  const countryName = countries.getName(userLocation.country, locale)
  const [isOpen, setIsOpen] = useState(!!countryName && show)
  const closeModal = () => setIsOpen(false)
  const handleConfirm = () => {
    closeModal()
    onConfirm()
  }

  if (!isOpen) {
    return null
  }

  const isCountryAllowed = theme.visible_regions.continents.reduce(
    (acc, continent) =>
      acc || continent.countries.includes(userLocation.country),
    false
  )

  const renderAllowedCountryMessage = () => (
    <p>
      {t('confirmation.description1')}
      <span
        className='confirmation-modal-bold-text'
        data-testid='confirmation-modal-bold-text'
      >
        {` ${countryName}. `}
      </span>
      {t('confirmation.description2')}
    </p>
  )
  const renderDisallowedCountryMessage = () => (
    <p>
      {t('confirmation.description1')}
      <span
        className='confirmation-modal-bold-text'
        data-testid='confirmation-modal-bold-text'
      >
        {` ${countryName}. `}
      </span>
      {t('confirmation.excluded_country')}
    </p>
  )

  return (
    <Modal
      show
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      className='confirmation-modal'
      data-testid='confirmation-modal'
      backdropClassName='header-offset'
      centered
    >
      <Modal.Header
        className='confirmation-modal-header'
        data-testid='confirmation-modal-header'
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          {t('confirmation.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='confirmation-modal-body'
        data-testid='confirmation-modal-body'
      >
        {isCountryAllowed
          ? renderAllowedCountryMessage()
          : renderDisallowedCountryMessage()}
      </Modal.Body>
      <Modal.Footer className='confirmation-modal-footer'>
        {isCountryAllowed && (
          <Button
            onClick={handleConfirm}
            className='confirmation-confirm-button'
            data-testid='confirmation-confirm-button'
          >
            {t('confirmation.confirmation_button')}
          </Button>
        )}
        <Button
          onClick={closeModal}
          className='confirmation-change-button'
          data-testid='confirmation-change-button'
        >
          {t('confirmation.change_button')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

CountryConfirm.propTypes = propTypes

export default CountryConfirm
