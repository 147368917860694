import React, { useState } from 'react'
import { Modal, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

const propTypes = {
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

const LabelOverlay = ({ theme, t }) => {
  const [show, setShow] = useState(false)

  const handleClick = () => setShow(true)
  const handleClose = () => setShow(false)

  return (
    <React.Fragment>
      <div className='labelOverlay-actions mt-4'>
        <p className='mb-2' data-testid='labelOverlay-heading'>
          <strong>{t('label_overlay.heading')}</strong>
        </p>
        <p data-testid='labelOverlay-paragraph'>
          <Trans i18nKey='label_overlay.body'>
            The REF can be found on the product label as shown
            <a
              onClick={handleClick}
              data-testid='labelOverlay-link'
              className='text-primary labelOverlay-link'
            >
              {theme.layout.overlay.link}
            </a>
            .
          </Trans>
        </p>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        data-testid='label-overlay'
        centered
        dialogClassName='connect-dialog'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('label_overlay.modal.heading')}</Modal.Title>
        </Modal.Header>

        <Modal.Body data-testid='label-overlay-body'>
          <div
            dangerouslySetInnerHTML={{ __html: t('label_overlay.modal.body') }}
          />
          <Image src={theme.label_image.url} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

LabelOverlay.propTypes = propTypes

export default withTranslation()(LabelOverlay)
