import React from 'react'
import { Button } from 'react-bootstrap'
import { Visibility } from '@styled-icons/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

const propTypes = {
  versionOfDocument: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

const ViewButton = ({ versionOfDocument, t }) => (
  <Button
    variant='primary'
    as={Link}
    to={`/document/${versionOfDocument.id}/${versionOfDocument.version}`}
  >
    <Visibility size='16' className='align-middle' />
    <span className='align-middle pl-2' data-testid='btn-view'>
      {t('results.view')}
    </span>
  </Button>
)

ViewButton.propTypes = propTypes

export default withTranslation()(ViewButton)
