import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import axios from '../connect/helpers/Api'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DocumentSection from './document/DocumentSection'
import { setDocument } from 'redux/actions/document'
import ErrorView from 'components/shared/ErrorView'

const propTypes = {
  reference: PropTypes.object,
  document: PropTypes.object,
  setDocument: PropTypes.func,
  subscribe: PropTypes.bool
}

const DocumentView = ({ reference, document, setDocument, theme: { subscribe } }) => {
  const { document: documentId, version: versionId } = useParams()
  const [error, setError] = useState(false)
  const history = useHistory()

  // #988: when redux-persist doesnt have the reference, user should be redirected to homepage
  if (!reference) {
    history.push('/')
  }

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const { data } = await axios.get(
          `/documents/${documentId}/${versionId}.json`
        )
        setDocument(data)
      } catch (err) {
        setError(true)
      }
    }

    fetchDocument()
  }, [documentId, versionId, setDocument])

  const LoadingView = () => (
    <div
      data-testid='LoadingView'
      className='d-flex align-items-center justify-content-center flex-grow-1'
    >
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  )

  const DisplayView = () => {
    if (document && versionId) {
      return (
        <DocumentSection
          document={document}
          reference={reference}
          versionId={versionId}
          subscribe={subscribe}
        />
      )
    } else if (error) {
      return <ErrorView reference={reference} />
    } else {
      return <LoadingView />
    }
  }

  return <DisplayView />
}

DocumentView.propTypes = propTypes

const mapStateToProps = ({ reference, document }) => ({ reference, document })

export default connect(mapStateToProps, { setDocument })(DocumentView)
