import React from 'react'
import { Card } from 'react-bootstrap'
import { Public } from '@styled-icons/material'
import countries from 'i18n-iso-countries'
import { Cookies } from 'react-cookie'
import PropTypes from 'prop-types'

import { SELECTED_COUNTRY_CODE_COOKIE_NAME } from 'components/connect/helpers/Constants'

const propTypes = {
  countryCode: PropTypes.string,
  locale: PropTypes.string
}

const CountryButton = ({ countryCode, locale }) => {
  const handleClick = () => {
    const cookies = new Cookies()
    cookies.set(SELECTED_COUNTRY_CODE_COOKIE_NAME, 'null', { path: '/' })
    window.location = '/'
  }

  return (
    <Card
      style={{ position: 'static' }}
      className='country-button-container'
      data-testid='country-button-container'
    >
      <Card.Body
        className='country-button'
        onClick={handleClick}
        data-testid='country-button'
      >
        <p className='country-button-title' data-testid='country-selected'>
          <span
            className='country-button-icon align-middle'
            data-testid='country-button-icon'
          >
            <Public size='16' />
          </span>
          {countries.getName(countryCode, locale)}
        </p>
      </Card.Body>
    </Card>
  )
}

CountryButton.propTypes = propTypes

export default CountryButton
