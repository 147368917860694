import React from 'react'
import { Spinner } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import i18n from './helpers/i18n'

const propTypes = {
  children: PropTypes.node,
}

const LoadingView = () => (
  <div
    data-testid="LoadingView"
    className="d-flex align-items-center justify-content-center vh-100"
  >
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

const I18nEnhancer = ({ children, tReady }) => (
  !tReady ? <LoadingView/> : <div data-testid="i18n-enhancer">{children}</div>
)

I18nEnhancer.propTypes = propTypes

export default withTranslation()(I18nEnhancer)
