import React from 'react'
import { Table, Dropdown, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import HistoryModal from './HistoryModal'
import _ from 'lodash'
import PropTypes from 'prop-types'
import SelectableContext from 'react-bootstrap/SelectableContext'

import { MoreHoriz, Description } from '@styled-icons/material'
import ViewButton from './ViewButton'

import locales from '../helpers/Locales'
import languages from '@cospired/i18n-iso-languages'
import { withTranslation } from 'react-i18next'

const propTypes = {
  reference: PropTypes.object,
  documents: PropTypes.array,
  locale: PropTypes.string,
  t: PropTypes.func.isRequired
}
const rowToolbarPropTypes = {
  document: PropTypes.object.isRequired
}
const tableRowPropTypes = {
  document: PropTypes.object.isRequired
}

_.forEach(locales, (locale) =>
  languages.registerLocale(require(`../helpers/i18n/langs/${locale}.json`))
)

const toTitleCase = (str) => {
  return !str
    ? ''
    : str
      .split(' ')
      .map(
        (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
      .join(' ')
}

const DocumentsByLanguage = ({ reference, documents, locale, t }) => {
  const translateLanguage = (lang) => languages.getName(lang, locale)
  const getLanguageStr = (document) =>
    _.join(
      _.map(document.languages, (lang) =>
        toTitleCase(languages.getName(lang, locale))
      ),
      ', '
    )

  const sortLanguages = (documents) => {
    return documents.sort((a, b) => {
      const firstLanguage = a.languages[0]
      const secondLanguage = b.languages[0]
      if (locale && secondLanguage.toLowerCase() === locale.toLowerCase())
        return 3
      if (locale && firstLanguage.toLowerCase() === locale.toLowerCase())
        return -3
      if (secondLanguage.toLowerCase() === 'en') return 2
      if (firstLanguage.toLowerCase() === 'en') return -2
      if (translateLanguage(firstLanguage) > translateLanguage(secondLanguage))
        return 1
      if (translateLanguage(secondLanguage) > translateLanguage(firstLanguage))
        return -1
      return 0
    })
  }

  const RowToolbar = ({ document }) => {
    const shouldRenderDropdown =
      document.versions[0].requestable || !!document.versions[0].valid_from

    return (
      <ButtonToolbar className='actions' data-testid='toolbar'>
        <SelectableContext.Provider value={false}>
          {shouldRenderDropdown && (
            <Dropdown
              as={ButtonGroup}
              className='mr-2'
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown.Toggle
                variant='outline-primary'
                className='bg-light'
                data-testid='dropdown-toggle'
              >
                <MoreHoriz size='16' className='align-middle' />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight>
                {document.versions[0].requestable && (
                  <Dropdown.Item
                    href={`/order/${document.versions[0].id}/${document.versions[0].version}`}
                    data-testid='dropdown-order'
                  >
                    {t('results.request_document')}
                  </Dropdown.Item>
                )}
                {document.versions[0].valid_from && (
                  <HistoryModal reference={reference} document={document} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <ViewButton
            reference={reference}
            versionOfDocument={document.versions[0]}
          />
        </SelectableContext.Provider>
      </ButtonToolbar>
    )
  }

  RowToolbar.propTypes = rowToolbarPropTypes

  const TableRow = ({ document }) => {
    return (
      <tr data-testid='row'>
        <td>
          <Description size='26' data-testid='file-icon' />
        </td>
        <td data-testid='languages' className='languages'>
          {getLanguageStr(document)}
        </td>
        <td>
          {!!document.versions.length && <RowToolbar document={document} />}
        </td>
      </tr>
    )
  }
  TableRow.propTypes = tableRowPropTypes

  return (
    <Table hover data-testid='table'>
      <tbody>
        {_.map(sortLanguages(documents), (document, key) => {
          return <TableRow key={key} document={document} />
        })}
      </tbody>
    </Table>
  )
}

DocumentsByLanguage.propTypes = propTypes

export default withTranslation()(DocumentsByLanguage)
