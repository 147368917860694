import { SET_DOCUMENT } from './../constants'

const initialState = null

export function documentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_DOCUMENT:
      return payload.document
    default:
      return state
  }
}

export default documentReducer
