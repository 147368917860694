import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import { Note } from '@styled-icons/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import FindForm from './FindForm'
import LabelOverlay from './LabelOverlay'
import { toBoolean } from 'components/shared/utils'

import 'react-bootstrap-typeahead/css/Typeahead.css'

const propTypes = {
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

const FindSection = ({ theme, t }) => (
  <Container
    data-testid='find-section'
    className='mt-5 mb-5 flex-grow-1 d-flex align-items-center'
  >
    <div className='d-flex align-items-center vw-100'>
      <Row className='flex-grow-1'>
        <Col lg={6} md={8}>
          <Card className='connect-card'>
            <Card.Body>
              <h3 data-testid='find-section-header' className='mb-5'>
                <strong>
                  {t('find_section.heading', {
                    company: theme.organisation_name
                  })}
                </strong>
              </h3>
              <FindForm theme={theme} />
              {toBoolean(_.get(theme, 'layout.overlay.show', 'false')) && (
                <LabelOverlay theme={theme} />
              )}
            </Card.Body>
            <Card.Footer>
              <div
                data-testid='find-section-pdf'
                className='text-secondary-dark'
              >
                <Note size='16' />
                <span className='pl-2'>{t('find_section.subtext')}</span>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  </Container>
)

FindSection.propTypes = propTypes

export default withTranslation()(FindSection)
