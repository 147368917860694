import isArray from 'lodash.isarray'
import isObject from 'lodash.isobject'
import mapKeys from 'lodash.mapkeys'
import mapValues from 'lodash.mapvalues'
import camelCase from 'lodash.camelcase'
import deepmerge from 'deepmerge'
import snakeCase from 'lodash.snakecase'
import { deburr } from 'lodash'

export const mapKeysDeep = (obj, cb) => {
  if (isArray(obj)) {
    return obj.map((o) => mapKeysDeep(o, cb))
  } else if (isObject(obj)) {
    return mapValues(mapKeys(obj, cb), (val) => mapKeysDeep(val, cb))
  }
  return obj
}

export const camelize = (obj) => mapKeysDeep(obj, (v, k) => camelCase(k))

export const decamelize = (obj) => mapKeysDeep(obj, (v, k) => snakeCase(k))

export const toBoolean = (str) => {
  if (str === undefined) {
    return false
  } else {
    try {
      return !!JSON.parse(str)
    } catch (err) {
      return false
    }
  }
}

export const filterByKeys = (hash, filteredKeys) =>
  filteredKeys.reduce((obj, key) => ({ ...obj, [key]: hash[key] }), {})

export const deepMerge = (obj1, obj2) =>
  deepmerge(obj1, obj2, {
    arrayMerge: (destination, source) => {
      return [...destination, ...source]
    }
  })

export const sortByKey = (array, key) => {
  const sortStrings = (element1, element2) => {
    if (deburr(element1[key]) < deburr(element2[key])) return -1
    if (deburr(element1[key]) > deburr(element2[key])) return 1
    return 0
  }
  return array.sort(sortStrings)
}

export const generateNumericArray = (len, start = 1) =>
  Array.from({ length: len }, (_, i) => i + start)
export const generateNumericArrayMinMax = (min, max) =>
  Array.from({ length: max - (min - 1) }, (_, i) => i + min)

export default toBoolean

export const isPublic = (status) =>
  status === 'released' ||
  status === 'retirement_requested' ||
  status === 'awaiting_retirement'
