import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ResultCard from './ResultCard'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const propTypes = {
  reference: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  userLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  t: PropTypes.func.isRequired
}

const ResultWrapper = ({ reference, product, userLocation, t }) => {
  const breadcrumbs = [
    { path: '/', name: t('breadcrumbs.home'), active: false },
    { path: `/products/${reference.id}`, name: reference.name, active: true }
  ]

  return (
    <div className='flex-grow-1 mb-5' data-testid='ResultWrapper'>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ResultCard
        reference={reference}
        product={product}
        userLocation={userLocation}
      />
    </div>
  )
}

ResultWrapper.propTypes = propTypes

export default withTranslation()(ResultWrapper)
