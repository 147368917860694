import React from 'react'
import styled from 'styled-components'
import { Container, Row, Image, Col } from 'react-bootstrap'
import { Cookies } from 'react-cookie'
import { SELECTED_COUNTRY_CODE_COOKIE_NAME } from 'components/connect/helpers/Constants'
import { find, includes } from 'lodash'

import PropTypes from 'prop-types'
import { Theme } from 'interfaces/Theme'

import FooterColumn from './FooterColumn'

const propTypes = {
  theme: Theme,
  metadata: PropTypes.object
}

const StyledFooter = styled.div`
  background-color: ${(props) => props.footer.background_color};
  color: ${(props) => props.footer.text_color};

  a {
    color: ${(props) => props.footer.text_color};
  }
`
const MAX_COLUMNS = 4

const ConnectFooter = ({ theme, metadata }) => {
  const numColumns = Number(MAX_COLUMNS)
  const cookies = new Cookies()
  const countryCookie = cookies.get(SELECTED_COUNTRY_CODE_COOKIE_NAME)

  let footer = null

  if (theme.country_selection) {
    footer = find(theme.footer, (footer) =>
      includes(footer.countries, countryCookie)
    )
  }

  const footerTheme = footer || theme.footer[0]
  const columns = footerTheme?.columns || []

  return (
    <StyledFooter className='pt-5 pb-5' footer={footerTheme}>
      <Container>
        <Row>
          {columns.map(
            (column, index) =>
              index < numColumns && (
                <FooterColumn key={index} column={column} count={numColumns} />
              )
          )}
        </Row>
        <Row>
          <Col>
            {!!theme.footer_logo?.url && (
              <Image
                src={theme.footer_logo.url}
                className='brand'
                data-testid='footer-logo'
              />
            )}
            <p className='software-version'>v{metadata.version || '-'}</p>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  )
}

ConnectFooter.propTypes = propTypes

export default ConnectFooter
