import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../connect/helpers/Api';
import { Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';

import CountryButton from './region/CountryButton';
import { setCountry } from 'redux/actions/userLocation';
import LanguageSelect from './region/LanguageSelect';
import {
  SELECTED_LANGUAGE_CODE_COOKIE_NAME,
  SELECTED_COUNTRY_CODE_COOKIE_NAME
} from 'components/connect/helpers/Constants';
import InfoIcon from 'components/connect/InfoIcon';

const propTypes = {
  theme: PropTypes.object,
  userLocation: PropTypes.object,
  setCountry: PropTypes.func,
  languages: PropTypes.array,
  onRegionView: PropTypes.bool,
  i18n: PropTypes.object,
  openInfoModal: PropTypes.func
};

const StyledHeader = styled.div`
  background-color: ${props => props.header.background_color};
  color: ${props => props.header.text_color};
`;

const ConnectHeader = ({
  theme,
  userLocation,
  setCountry,
  languages,
  onRegionView,
  i18n,
  openInfoModal
}) => {
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const { data } = await axios.get('/country_location.json');
        setCountry(data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCountry();
  }, [setCountry]);

  const cookies = new Cookies();

  const handleChangeLanguage = languageCode => {
    i18n.changeLanguage(languageCode.toLowerCase());
    cookies.set(SELECTED_LANGUAGE_CODE_COOKIE_NAME, languageCode, {
      expires: Moment()
        .add(30, 'minutes')
        .toDate()
    });
  };

  const language = cookies.get(SELECTED_LANGUAGE_CODE_COOKIE_NAME);
  const countryCode = cookies.get(SELECTED_COUNTRY_CODE_COOKIE_NAME);

  if (!userLocation) return null;

  const countrySelectionCheck = !onRegionView && theme.country_selection;

  const infoIconPosition = {
    xs: { span: 2, offset: countrySelectionCheck ? 2 : 4 },
    md: { span: 1, offset: countrySelectionCheck ? 0 : 4 },
    lg: { span: 1, offset: countrySelectionCheck ? 2 : 4 }
  };

  return (
    <StyledHeader
      className="connect-header"
      data-testid="connect-header"
      header={theme.header}
      layout={theme.layout}
    >
      <Container>
        <Row xs={'auto'} md={'auto'} lg={'auto'}>
          <Col xs={3} md={3} lg={3}>
            <Link to="/">
              <Image
                src={theme.logo.url}
                className="brand"
                data-testid="header-logo"
              />
            </Link>
          </Col>
          <Col {...infoIconPosition}>
            <InfoIcon
              countrySelection={countrySelectionCheck}
              handleClick={openInfoModal}
              color={theme.header.text_color}
            />
          </Col>
          {countrySelectionCheck && (
            <Col xs={2} md={4} lg={'auto'}>
              <CountryButton countryCode={countryCode} locale={language} />
            </Col>
          )}
          <Col xs={2} md={4} lg={'auto'}>
            <LanguageSelect
              languages={languages}
              onClick={handleChangeLanguage}
            />
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

ConnectHeader.propTypes = propTypes;

const mapStateToProps = ({ userLocation }) => ({ userLocation });

export default connect(mapStateToProps, { setCountry })(
  withTranslation()(ConnectHeader)
);
