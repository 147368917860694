import React, { useState } from 'react'
import { Table, Dropdown, Modal } from 'react-bootstrap'
import { Close, Schedule } from '@styled-icons/material'
import ViewButton from './ViewButton'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

const propTypes = {
  reference: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

const tableRowPropTypes = {
  reference: PropTypes.object.isRequired,
  versionOfDocument: PropTypes.object.isRequired
}

const RAW_DATE_FORMAT = 'DD.MM.YYYY'
const USER_DATE_FORMAT = 'MMM DD, YYYY'

const TableRow = ({ reference, versionOfDocument }) => (
  <tr data-testid='row'>
    <td data-testid='validFrom'>
      {moment(versionOfDocument.valid_from, RAW_DATE_FORMAT).format(
        USER_DATE_FORMAT
      )}
    </td>
    <td>
      <div className='actions' data-testid='actions'>
        <ViewButton
          reference={reference}
          versionOfDocument={versionOfDocument}
        />
      </div>
    </td>
  </tr>
)

const HistoryModal = ({ reference, document, t }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const sortByValidFrom = (versions) => {
    const sortedVersions = versions.sort((a, b) => {
      const first = moment(a.valid_from, RAW_DATE_FORMAT)
      const second = moment(b.valid_from, RAW_DATE_FORMAT)
      return first.isBefore(second) ? 1 : -1
    })
    return sortedVersions
  }

  return (
    <React.Fragment>
      <Dropdown.Item onClick={handleShow} data-testid='dropdown-history'>
        {t('results.view_history')}
      </Dropdown.Item>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        data-testid='history-modal'
      >
        <Modal.Header>
          <Modal.Title className='w-100' data-testid='header'>
            <Schedule size='24' />
            <span className='pl-2 align-middle'>
              {t('results.document_history')}
            </span>
            <div className='float-right'>
              <Close
                size='24'
                className='align-middle'
                onClick={handleClose}
                data-testid='close'
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            hover
            borderless
            data-testid='table'
            className='document-history-table'
          >
            <thead>
              <tr>
                <td>{t('results.valid_from')}</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {_.map(sortByValidFrom(document.versions), (version, key) => (
                <TableRow
                  key={key}
                  reference={reference}
                  versionOfDocument={version}
                />
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

TableRow.propTypes = tableRowPropTypes
HistoryModal.propTypes = propTypes

export default withTranslation()(HistoryModal)
