import React, { useState } from 'react'
import _ from 'lodash'
import { Card, Accordion } from 'react-bootstrap'
import { ArrowDropDown, ArrowRight, Language } from '@styled-icons/material'
import ReactCountryFlag from 'react-country-flag'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import countries from 'i18n-iso-countries'
import PropTypes from 'prop-types'

import DocumentsByLanguage from './DocumentsByLanguage'
import states from '../helpers/States'
import { toBoolean } from 'components/shared/utils'
// import locales from 'components/connect/helpers/Locales';

const propTypes = {
  reference: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired
}
const accordionHeaderPropTypes = {
  countryCode: PropTypes.string.isRequired
}

// _.forEach(locales, (locale) =>
//   countries.registerLocale(require(`../helpers/i18n/countries/${locale}.json`)),
// );

const DocumentsByCountry = ({
  reference,
  product,
  documents,
  theme,
  country
}) => {
  const { i18n } = useTranslation()
  const locale = i18n.language.slice(0, 2) // we need to slice to convert 'en-US' into 'en', for instance
  const groupByCountry = toBoolean(_.get(theme, 'layout.group_by_country', 'true'))
  const mergeEU = toBoolean(_.get(theme, 'layout.merge_eu', 'false'))

  const handleClick = (key) => {
    if (selectedAccordion === key) {
      setSelectedAccordion('')
    } else {
      setSelectedAccordion(key)
    }
  }

  const groupedDocuments = _.transform(documents, (result, value) => {
    value.countries.forEach(countryCode => {
      if (!groupByCountry) {
        countryCode = 'TE'
      }
      if (groupByCountry && states.includes(countryCode) && mergeEU) {
        countryCode = 'EU'
      }
      if ((result[countryCode] || (result[countryCode] = [])).indexOf(value) < 0) {
        result[countryCode].push(value)
      }
    })
  }, {})

  const translateCountryCode = (CC) => countries.getName(CC, locale)

  const sortDocuments = (documents) => {
    const userLocation = country
    const EUUser = country && _.includes(states, userLocation)

    return Object.entries(documents)
      .sort((a, b) => {
        if (mergeEU && EUUser && documents.EU && b[0] === 'EU') return 3
        if (mergeEU && EUUser && documents.EU && a[0] === 'EU') return -3
        if (userLocation && documents[userLocation] && b[0] === userLocation)
          return 2
        if (userLocation && documents[userLocation] && a[0] === userLocation)
          return -2
        if (translateCountryCode(a[0]) > translateCountryCode(b[0])) return 1
        if (translateCountryCode(b[0]) > translateCountryCode(a[0])) return -1
        return 0
      })
      .reduce((map, array) => {
        map[array[0]] = array[1]
        return map
      }, {})
  }

  const [selectedAccordion, setSelectedAccordion] = useState(
    _.keys(sortDocuments(groupedDocuments))[0]
  )

  const AccordionHeader = ({ countryCode }) => {
    const Caret = () => {
      if (selectedAccordion === countryCode) {
        return (
          <ArrowDropDown size='24' className='align-middle ml-3 text-primary' />
        )
      } else {
        return (
          <ArrowRight size='24' className='align-middle ml-3 text-primary' />
        )
      }
    }
    return (
      <h6>
        {countryCode === 'TE'
          ? <Language size='24' className='align-middle' />
          : <ReactCountryFlag countryCode={countryCode} className='flag' svg />
        }
        <span className='align-middle ml-3' data-testid='country'>
          {countryCode === 'TE' ? i18n.t('results.global_label') : translateCountryCode(countryCode)}
        </span>
        <Caret />
      </h6>
    )
  }
  AccordionHeader.propTypes = accordionHeaderPropTypes

  const accordionCards = _.map(
    sortDocuments(groupedDocuments),
    (documents, key) => (
      <Card key={key} data-testid='accordion-card'>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={key}
          onClick={() => handleClick(key)}
          data-testid='accordion-toggle'
        >
          <AccordionHeader countryCode={key} data-testid='accordion-header' />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={key} data-testid='accordion-collapse'>
          <Card.Body data-testid='accordion-card-body'>
            <DocumentsByLanguage
              reference={reference}
              documents={documents}
              product={product}
              locale={locale}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  )

  return (
    <Accordion
      defaultActiveKey={_.keys(sortDocuments(groupedDocuments))[0]}
      data-testid='accordion'
    >
      {accordionCards}
    </Accordion>
  )
}

DocumentsByCountry.propTypes = propTypes

const mapStateToProps = ({ theme }) => ({ theme })

export default connect(mapStateToProps, null)(DocumentsByCountry)
