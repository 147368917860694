// --- THEME ---
export const SET_THEME = 'SET_THEME'

// --- DOCUMENT ---
export const SET_DOCUMENT = 'SET_DOCUMENT'

// --- PRODUCT ---
export const SET_PRODUCT = 'SET_PRODUCT'

// --- USER_LOCATION ---
export const SET_COUNTRY = 'SET_COUNTRY'

// --- ORGANISATION ---
export const SET_ORGANISATION = 'SET_ORGANISATION'

// --- REFERENCE ---
export const SET_REFERENCE = 'SET_REFERENCE'
