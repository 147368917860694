import React from 'react'
import { Alert, Col, Button } from 'react-bootstrap'
import { Download, Send, Visibility } from '@styled-icons/material'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const propTypes = {
  document: PropTypes.object,
  showNotification: PropTypes.func,
  versionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  history: PropTypes.object,
  documentSignedURL: PropTypes.string,
  t: PropTypes.func.isRequired
}

const DocumentActions = ({
  document,
  showNotification,
  versionId,
  history,
  t,
  documentSignedURL
}) => {
  const haveNotifications = !_.isEmpty(document.notifications)
  const requestDocument = () =>
    history.push(`/order/${document.id}/${versionId}`)

  const renderActionButton = (label, dataTestId, Icon, onClick) => (
    <Button
      variant='link'
      onClick={onClick}
      data-testid={dataTestId}
      className='btn btn-link pl-0 action-btn'
    >
      <Icon size='16' className='mr-2' />
      {label}
    </Button>
  )

  const renderDownloadButton = () => (
    <a
      download='document.pdf'
      data-testid='document-download'
      className='btn btn-link pl-0 action-btn'
      onClick={() =>
        window.open(new URL(documentSignedURL, window.location.origin).href)
      }
    >
      <Download size='16' className='mr-2' />
      {t('document_actions.download')}
    </a>
  )

  return (
    <Col data-testid='document-actions'>
      <h3 data-testid='document-heading'>
        <strong>{t('document_actions.actions')}</strong>
      </h3>
      <p className='description' data-testid='document-description'>
        {t('document_actions.description')}
      </p>

      <div className='actions-wrapper'>
        {renderDownloadButton()}
        {document.requestable &&
          renderActionButton(
            t('document_actions.order_document'),
            'document-request',
            Send,
            requestDocument
          )}
        {haveNotifications &&
          renderActionButton(
            t('document_actions.view_notifications'),
            'document-notification-link',
            Visibility,
            showNotification
          )}
      </div>
      {(!document.latest && !document.lot_based) && (
        <Alert
          variant='warning'
          className='mt-2'
          data-testid='document-notification'
        >
          {t('document_actions.version_warning')}
        </Alert>
      )}
    </Col>
  )
}

DocumentActions.propTypes = propTypes

export default withRouter(withTranslation()(DocumentActions))
