import React from 'react'
import { Form, Button, Col, Spinner } from 'react-bootstrap'
import countryCodes from 'country-codes-list'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

const propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  t: PropTypes.func.isRequired
}

const OrderForm = ({
  handleSubmit,
  handleChange,
  values,
  isSubmitting,
  errors,
  t
}) => {
  const countries = countryCodes.customList('countryCode', '{countryNameEn}')

  const sortByCountryName = (c1, c2) => {
    if (_.deburr(c1[1]) < _.deburr(c2[1])) return -1
    if (_.deburr(c1[1]) > _.deburr(c2[1])) return 1
    return 0
  }

  const renderField = (name, value, handleChange, error, label) => (
    <Form.Row>
      <Form.Group as={Col} md='12' controlId={name}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={name === 'email' ? 'email' : 'text'}
          name={name}
          value={value}
          onChange={handleChange}
          data-testid={`order-form-${name.toLowerCase()}`}
          isInvalid={!!error}
        />
        <Form.Control.Feedback type='invalid'>
          {t(error, { label: label })}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  )

  const renderTitleAndFirstName = (values, errors, handleChange) => (
    <Form.Row>
      <Form.Group as={Col} md='3' controlId='title'>
        <Form.Label>{t('order_form.labels.title')}</Form.Label>
	<Form.Control
	  type='text'
	  name='title'
	  value={values.title}
	  onChange={handleChange}
	  data-testid='order-form-title'
	  isInvalid={!!errors.title}
	/>
        <Form.Control.Feedback type='invalid'>
          {t(errors.title, { label: t('order_form.labels.title') })}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md='9' controlId='firstName'>
        <Form.Label>{t('order_form.labels.firstname')}</Form.Label>
        <Form.Control
          type='text'
          name='firstName'
          value={values.firstName}
          onChange={handleChange}
          data-testid='order-form-firstname'
          isInvalid={!!errors.firstName}
        />
        <Form.Control.Feedback type='invalid'>
          {t(errors.firstName, { label: t('order_form.labels.firstname') })}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  )

  const renderPostcodeAndCity = (values, errors, handleChange) => (
    <Form.Row>
      <Form.Group as={Col} md='3' controlId='postcode'>
        <Form.Label>{t('order_form.labels.postcode')}</Form.Label>
        <Form.Control
          type='text'
          name='postcode'
          value={values.postcode}
          onChange={handleChange}
          data-testid='order-form-postcode'
          isInvalid={!!errors.postcode}
        />
        <Form.Control.Feedback type='invalid'>
          {t(errors.postcode, { label: t('order_form.labels.postcode') })}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md='9' controlId='city'>
        <Form.Label>{t('order_form.labels.city')}</Form.Label>
        <Form.Control
          type='text'
          name='city'
          value={values.city}
          onChange={handleChange}
          data-testid='order-form-city'
          isInvalid={!!errors.city}
        />
        <Form.Control.Feedback type='invalid'>
          {t(errors.city, { label: t('order_form.labels.city') })}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  )

  const renderCountry = (values, errors, handleChange) => (
    <Form.Row>
      <Form.Group as={Col} md='12' controlId='country'>
        <Form.Label>{t('order_form.labels.country')}</Form.Label>
        <Form.Control
          as='select'
          name='country'
          value={values.country}
          onChange={handleChange}
          data-testid='order-form-country'
          isInvalid={!!errors.country}
        >
          <option>{t('order_form.placeholders.country')}</option>
          {Object.entries(countries)
            .sort(sortByCountryName)
            .map((array) => (
              <option value={array[0]} key={array[0]}>
                {array[1]}
              </option>
            ))}
        </Form.Control>
        <Form.Control.Feedback type='invalid'>
          {t(errors.country, { label: t('order_form.labels.country') })}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  )

  const renderConsentAndButton = (isSubmitting) => (
    <Form.Row>
      <Form.Group as={Col} md='9' className='mt-4'>
        <Form.Text
          className='mt-2 order-form-consent'
          data-testid='order-form-consent'
        >
          {t('order_form.consent')}
        </Form.Text>
      </Form.Group>
      <Form.Group as={Col} md='3' className='mt-4'>
        <Button
          variant='primary'
          type='submit'
          className='mt-2'
          data-testid='order-form-button'
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            >
              <span className='sr-only'>Loading...</span>
            </Spinner>
          ) : (
            t('order_form.button')
          )}
        </Button>
      </Form.Group>
    </Form.Row>
  )

  return (
    <Form onSubmit={handleSubmit} data-testid='order-form'>
      {renderTitleAndFirstName(values, errors, handleChange)}
      {renderField(
        'lastName',
        values.lastName,
        handleChange,
        errors.lastName,
        t('order_form.labels.lastname')
      )}
      {renderField(
        'telephone',
        values.telephone,
        handleChange,
        errors.telephone,
        t('order_form.labels.telephone')
      )}
      {renderField(
        'email',
        values.email,
        handleChange,
        errors.email,
        t('order_form.labels.email')
      )}
      {renderField(
        'company',
        values.company,
        handleChange,
        errors.company,
        t('order_form.labels.company')
      )}
      {renderField(
        'address1',
        values.address1,
        handleChange,
        errors.address1,
        t('order_form.labels.address1')
      )}
      {renderField(
        'address2',
        values.address2,
        handleChange,
        errors.address2,
        t('order_form.labels.address2')
      )}
      {renderPostcodeAndCity(values, errors, handleChange)}
      {renderCountry(values, errors, handleChange)}
      {renderField(
        'notes',
        values.notes,
        handleChange,
        errors.notes,
        t('order_form.labels.notes')
      )}
      {renderConsentAndButton(isSubmitting)}
    </Form>
  )
}

OrderForm.propTypes = propTypes

export default withTranslation()(OrderForm)
