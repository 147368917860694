import { combineReducers } from 'redux'
import theme from './theme'
import document from './document'
import product from './product'
import userLocation from './userLocation'
import organisation from './organisation'
import reference from './reference'

export default combineReducers({
  theme,
  document,
  product,
  userLocation,
  organisation,
  reference
})
