/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Translate } from '@styled-icons/material'
import { KeyboardArrowUp } from '@styled-icons/material/KeyboardArrowUp'
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown'
import * as isoLanguages from '@cospired/i18n-iso-languages'
import _ from 'lodash'
import { Cookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import Moment from 'moment'
import PropTypes from 'prop-types'

import LanguageItem from './LanguageItem'
import { SELECTED_LANGUAGE_CODE_COOKIE_NAME } from 'components/connect/helpers/Constants'
import locales from 'components/connect/helpers/Locales'

_.forEach(locales, (locale) =>
  isoLanguages.registerLocale(
    require(`components/connect/helpers/i18n/langs/${locale}.json`)
  )
)
const DEFAULT_LANGUAGE = 'English'
const DEFAULT_LOCALE = 'EN'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  languages: PropTypes.array
}

const LanguageSelect = ({ onClick, languages }) => {
  const cookies = new Cookies()
  const [isOpen, setIsOpen] = useState(false)
  const toggleButton = () => setIsOpen(!isOpen)
  const { i18n } = useTranslation()

  const getSelectedLanguage = () => {
    const locale = i18n.language.slice(0, 2)
    const cookieValue = cookies.get(SELECTED_LANGUAGE_CODE_COOKIE_NAME)

    let selectedLanguage = null
    if (cookieValue) {
      selectedLanguage = isoLanguages.getName(cookieValue, cookieValue)
    } else if (!!locale) {
      selectedLanguage = isoLanguages.getName(locale, locale)
      cookies.set(SELECTED_LANGUAGE_CODE_COOKIE_NAME, locale, {
        expires: Moment()
          .add(30, 'minutes')
          .toDate()
      })
    } else {
      selectedLanguage = DEFAULT_LANGUAGE
      cookies.set(SELECTED_LANGUAGE_CODE_COOKIE_NAME, DEFAULT_LOCALE, {
        expires: Moment()
          .add(30, 'minutes')
          .toDate()
      })
    }
    return selectedLanguage
  }

  const sortedLanguages = () => {
    const translatedLanguages = languages.map((language) => ({
      name: _.upperFirst(isoLanguages.getName(language, language)),
      code: language
    }))
    return _.sortBy(translatedLanguages, ['name'])
  }

  return (
    <Card className={cx('language-selector', { closed: !isOpen })}>
      <Card.Body
        className='language-button'
        onClick={toggleButton}
        data-testid='language-button'
      >
        <span>
          <span className='language-button-icon align-middle'>
            <Translate size='18' />
          </span>
          <span
            className='language-button-title'
            data-testid='language-selected'
          >
            {_.upperFirst(getSelectedLanguage())}
          </span>
          <span className='float-right language-button-arrow'>
            {isOpen ? (
              <KeyboardArrowUp size='28' />
            ) : (
              <KeyboardArrowDown size='28' />
            )}
          </span>
        </span>
        {isOpen && (
          <div className='language-list' data-testid='language-list'>
            {sortedLanguages().map((language) => (
              <LanguageItem
                key={language.code}
                onClick={onClick}
                language={language}
              />
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

LanguageSelect.propTypes = propTypes

export default LanguageSelect
