import React from 'react'
import { Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const propTypes = {
  organisation: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

const DemoDisclaimer = ({ organisation, t }) =>
  organisation.demo && (
    <Alert className='demo-disclaimer'>{t('demo_disclaimer')}</Alert>
  )

DemoDisclaimer.propTypes = propTypes

export default withTranslation()(DemoDisclaimer)
