import React, { useState } from 'react'
import { Col, Modal } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

const propTypes = {
  column: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired
}

const menuColumnPropTypes = {
  column: PropTypes.object.isRequired
}

const menuItemPropTypes = {
  item: PropTypes.object.isRequired
}

const MenuItem = ({ item }) => {
  const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

  const RichTextItem = () => ReactHtmlParser(item.richtext)
  const UrlItem = () => (
    <a href={withHttp(item.link)} target='_blank' rel='noopener noreferrer'>
      {item.label}
    </a>
  )
  const TextItem = () => <span>{item.label}</span>
  const ModalItem = () => {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
      <React.Fragment>
        <span onClick={handleShow} className='footer-column-modal'>
          {item.label}
        </span>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          dialogClassName='connect-dialog'
        >
          <Modal.Header closeButton>
            <Modal.Title>{item.modal.heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{ReactHtmlParser(item.modal.body)}</Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }

  switch (item.type) {
    case 'richtext':
      return <RichTextItem />
    case 'link':
      return <UrlItem />
    case 'text':
      return <TextItem />
    case 'modal':
      return <ModalItem />

    default:
      return <React.Fragment />
  }
}

const MenuColumn = ({ column }) => (
  <ul className='list-unstyled'>
    {column.rows &&
      column.rows.map(
        (item, index) =>
          index < Number(column.number_of_rows) && (
            <li key={index} className='mb-2'>
              <MenuItem item={item} />
            </li>
          )
      )}
  </ul>
)

const FooterColumn = ({ column, count }) => {
  const width = count === 4 ? 3 : count === 3 ? 4 : count === 2 ? 2 : 1

  return (
    <Col md={width} className='mb-4' data-testid='footer-column'>
      <h6 className='text-uppercase mb-3'>
        <strong>{column.label}</strong>
      </h6>
      <MenuColumn column={column} />
    </Col>
  )
}

FooterColumn.propTypes = propTypes
MenuColumn.propTypes = menuColumnPropTypes
MenuItem.propTypes = menuItemPropTypes

export default FooterColumn
