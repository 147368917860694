import React from 'react'
import { Modal, Badge } from 'react-bootstrap'
import { Info, Warning } from '@styled-icons/material'
import PropTypes from 'prop-types'

const propTypes = {
  notifications: PropTypes.array.isRequired
}

const NotificationBody = ({ notifications }) => {
  const SingleNotificationContainer = () => (
    <div data-testid='notification-single'>
      <p>{notifications[0].description}</p>
    </div>
  )

  const MultipleNotificationContainer = () => (
    <div data-testid='notification-multiple'>
      {notifications.map((notif, idx) => renderNotificationRow(notif, idx))}
    </div>
  )

  const renderNotificationRow = (notification, index) => (
    <div key={index}>
      <Badge
        className={`notification-badge ${notification.category}`}
        variant='light'
      >
        {notification.category === 'info' ? (
          <Info size='14' data-testid={`notification-body-icon${index}`} />
        ) : (
          <Warning
            size='14'
            className='alert-triangle'
            data-testid={`notification-body-icon${index}`}
          />
        )}
        {notification.title}
      </Badge>
      <p data-testid={`notification-body-description${index}`}>
        {notification.description}
      </p>
    </div>
  )

  return (
    <Modal.Body>
      {notifications.length === 1 ? (
        <SingleNotificationContainer />
      ) : (
        <MultipleNotificationContainer />
      )}
    </Modal.Body>
  )
}

NotificationBody.propTypes = propTypes

export default NotificationBody
