import React, { useEffect, useState } from 'react'
import { withCookies } from 'react-cookie'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Moment from 'moment'
import { CONSENTED_COOKIE_NAME } from 'components/connect/helpers/Constants'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'
import prependHttp from 'prepend-http'

import { toBoolean } from 'components/shared/utils'
import 'components/connect/helpers/i18n'

const propTypes = {
  cookies: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export const CookieStatement = ({ cookies, theme, t }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const isVisible = () =>
      toBoolean(theme.cookies.show) && !cookies.get(CONSENTED_COOKIE_NAME)

    setShow(isVisible())
  }, [setShow, theme.cookies.show, cookies])

  const handleClick = () => {
    cookies.set(CONSENTED_COOKIE_NAME, 'true', {
      expires: Moment()
        .add(1, 'days')
        .toDate()
    })
    setShow(false)
  }

  return (
    show && (
      <div className='cookies'>
        <Container>
          <Row>
            <Col
              xs='12'
              md='8'
              lg='10'
              className='d-flex align-items-center pt-2 pb-2'
            >
              <p>
                <Trans i18nKey='cookie.text'>
                  This website uses cookies to improve your experience. By
                  continuing, you agree to this use of cookies. If you require
                  further information please visit
                  <a
                    href={prependHttp(theme.cookies?.url || '')}
                    className='text-primary labelOverlay-link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    About Cookies
                  </a>
                  .
                </Trans>
              </p>
            </Col>
            <Col className='d-flex align-items-center pt-2 pb-2'>
              <Button
                variant='primary'
                size='lg'
                onClick={handleClick}
                data-testid='cookies-button'
                block
              >
                {t('cookie.button')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  )
}

CookieStatement.propTypes = propTypes

export default withCookies(withTranslation()(CookieStatement))
