import PropTypes from 'prop-types'

export const Theme = PropTypes.shape({
  id: PropTypes.string,
  logo: PropTypes.shape({
    url: PropTypes.string
  }),
  icon_fav: PropTypes.shape({
    url: PropTypes.string
  }),
  label_image: PropTypes.shape({
    url: PropTypes.string
  }),
  footer_logo: PropTypes.shape({
    url: PropTypes.string
  }),
  translations_file: PropTypes.shape({
    url: PropTypes.string
  }),
  header: PropTypes.shape({
    tagline: PropTypes.string,
    address_1: PropTypes.string,
    address_2: PropTypes.string,
    address_3: PropTypes.string,
    address_4: PropTypes.string
  }),
  footer: PropTypes.arrayOf(
    PropTypes.shape({
      number_of_columns: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      columns: PropTypes.oneOfType([PropTypes.array]),
      background_color: PropTypes.string,
      countries: PropTypes.array
    })
  ),
  layout: PropTypes.shape({
    background_color: PropTypes.string
  }),
  visible_content_set: PropTypes.array,
  iconFavPreview: PropTypes.bool,
  logoPreview: PropTypes.string,
  pop_ups: PropTypes.shape({
    cookie_statement: PropTypes.bool,
    for_professionals: PropTypes.bool,
    for_professionals_content: PropTypes.object
  }),
  visible_regions: PropTypes.shape({
    continents: PropTypes.array
  }),
  organisation: PropTypes.shape({
    content_sets: PropTypes.array
  })
})
