import React from 'react'
import { withCookies } from 'react-cookie'
import Moment from 'moment'
import PropTypes from 'prop-types'

import { SELECTED_COUNTRY_CODE_COOKIE_NAME } from 'components/connect/helpers/Constants'

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  cookies: PropTypes.object,
  country: PropTypes.object
}

const CountryListItem = ({ onSelect, cookies, country }) => {
  const handleClick = () => {
    cookies.set(SELECTED_COUNTRY_CODE_COOKIE_NAME, country.code, {
      expires: Moment()
        .add(30, 'minutes')
        .toDate(),
      path: '/'
    })
    onSelect()
  }
  return (
    <div>
      <p
        data-testid='country-name'
        onClick={handleClick}
        className='country-list-item'
      >
        {country.name}
      </p>
    </div>
  )
}

CountryListItem.propTypes = propTypes

export default withCookies(CountryListItem)
