import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { toBoolean } from 'components/shared/utils'

const propTypes = {
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

const HeaderTags = ({ theme, t }) => (
  <Helmet>
    <title>
      {t('header_tags.title', { company: theme.organisation_name })}
    </title>
    <link rel='icon' href={theme.icon_fav.url} />
    {theme.iconFavPreview ? (
      <link rel='icon' href={theme.iconFavPreview} />
    ) : (
      <link rel='icon' href={theme.icon_fav.url} />
    )}
    <meta
      name='description'
      content={t('header_tags.description', {
        company: theme.organisation_name
      })}
    />
    {typeof theme.header.search_engine_indexing !== 'undefined' &&
      !toBoolean(theme.header.search_engine_indexing) && (
        <meta name='robots' content='noindex' />
      )}
  </Helmet>
)

HeaderTags.propTypes = propTypes

export default withTranslation()(HeaderTags)
