import React from 'react'
import { AsyncTypeahead, Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import mixpanel from 'mixpanel-browser'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'

const typeaheadAdapterPropTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  typeaheadId: PropTypes.string.isRequired,
  typeaheadPlaceholder: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

const asyncTypeaheadAdapterPropTypes = {
  ...typeaheadAdapterPropTypes,
  isLoading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
}

const customMenuPropTypes = {
  results: PropTypes.array.isRequired,
  labelKey: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

const HEX_APLHA_30 = '4d'
const alphasizeColor = (color) => `${color}${HEX_APLHA_30}`
const sequenceMatchFilter = (option, { labelKey, text }) => {
  return option[labelKey].match(new RegExp(`^${_.escapeRegExp(text)}`, 'i')) != null
}

const StyledTypeahead = styled(Typeahead)`
  #referencesQuery {
    border-color: ${({ theme }) =>
      theme.layout.button_background_color} !important;
  }

  .dropdown-item {
    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) =>
        alphasizeColor(theme.layout.button_background_color)};
      color: ${() => '#000000'} !important;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: ${({ theme }) =>
      alphasizeColor(theme.layout.button_background_color)};
    color: ${() => '#000000'} !important;
  }
`

const StyledSpan = styled.span`
  border-color: ${({ theme }) =>
    theme.layout.button_background_color} !important;
  color: ${({ theme }) => theme.layout.button_background_color} !important;
`

const CustomMenu = ({ results, labelKey, theme, ...menuProps }) => {
  if (!results.length) return null

  return (
    <Menu {...menuProps}>
      {results.map((option, position) => (
        <MenuItem
          key={option.id}
          option={option}
          position={position}
        >
          <StyledSpan className='refBox align-middle' theme={theme}>
            ___
          </StyledSpan>
          <span className='align-middle ml-3'>{option[labelKey]}</span>
        </MenuItem>
      ))}
    </Menu>
  )
}

CustomMenu.propTypes = customMenuPropTypes

export const AsyncTypeaheadAdapter = ({
  isLoading,
  options,
  onSearch,
  selected,
  labelKey,
  onChange,
  typeaheadId,
  dataTestId,
  typeaheadPlaceholder,
  theme
}) => {
  const handleInputChange = (query) => {
    mixpanel.track('TypeaheadAdapter.handleChange', { reference: query })
  }

  const handleChange = (selected) => {
    if (selected.length) {
      mixpanel.track('TypeaheadAdapter.handleChange', { reference: selected[0].name })
    }
    onChange(selected)
  }

  const renderMenu = (results, menuProps) => {
    if (results.length && theme.ref_typeahead) {
      return <CustomMenu {...menuProps} labelKey={labelKey} results={results} theme={theme} />
    }
    return null
  }

  return (
    <StyledTypeahead
      as={AsyncTypeahead}
      id={typeaheadId}
      dataTestId={dataTestId}
      inputProps={{ id: typeaheadId, autoComplete: 'off' }}
      isLoading={theme.ref_typeahead && isLoading}
      onInputChange={handleInputChange}
      labelKey={labelKey}
      onChange={handleChange}
      selected={selected}
      onSearch={onSearch}
      options={options}
      placeholder={typeaheadPlaceholder}
      renderMenu={renderMenu}
      filterBy={sequenceMatchFilter}
      useCache={false}
      theme={theme}
      minLength={theme.ref_typeahead}
      bsSize='large'
      size='lg'
      type='text'
      highlightOnlyResult
      autoFocus
    />
  )
}

AsyncTypeaheadAdapter.propTypes = asyncTypeaheadAdapterPropTypes

export const TypeaheadAdapter = ({
  typeaheadId,
  dataTestId,
  typeaheadPlaceholder,
  options,
  onChange,
  selected,
  labelKey,
  theme,
}) => {
  const handleInputChange = (query) => {
    mixpanel.track('TypeaheadAdapter.handleChange', { reference: query })
  }

  const handleChange = (selected) => {
    if (selected.length) {
      mixpanel.track('TypeaheadAdapter.handleChange', { reference: selected[0].title })
    }
    onChange(selected)
  }

  const renderMenu = (results, menuProps) => {
    if (results.length && theme.lot_typeahead) {
      return <CustomMenu {...menuProps} labelKey={labelKey} results={results} theme={theme} />
    }
    return null
  }

  return (
    <StyledTypeahead
      id={typeaheadId}
      data-testid={dataTestId}
      inputProps={{ id: typeaheadId, autoComplete: 'off' }}
      options={options}
      onInputChange={handleInputChange}
      labelKey={labelKey}
      onChange={handleChange}
      selected={selected}
      placeholder={typeaheadPlaceholder}
      renderMenu={renderMenu}
      filterBy={sequenceMatchFilter}
      theme={theme}
      minLength={theme.lot_typeahead}
      bsSize='large'
      size='lg'
      type='text'
      highlightOnlyResult
      autoFocus
    />
  )
}

TypeaheadAdapter.propTypes = typeaheadAdapterPropTypes

export default TypeaheadAdapter
