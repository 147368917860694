import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withTranslation } from 'react-i18next'
import { hex } from 'wcag-contrast'

import { Container, Row, Col } from 'react-bootstrap'

const TEXT_COLOR = '#120340'
const CONTRAST_THRESHOLD = 2

const NoMatchView = ({ theme, t }) => {
  const [hasContrast, setHasContrast] = useState(null)

  useEffect(() => {
    const bgColor = theme.layout.background_color
    setHasContrast(hex(bgColor, TEXT_COLOR) < CONTRAST_THRESHOLD)
  }, [])

  return (
    <Container className='no-match-wrapper'>
      <Row>
        <Col lg={4} md={6}>
          <div className={classNames({ 'reverse-color': hasContrast })}>
            <h1 className='status'>404</h1>
            <h2 className='title'>{t('no_match.title')}</h2>
            <p className='message'>{t('no_match.message')}</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

NoMatchView.propTypes = {
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(NoMatchView)
