import { SET_PRODUCT } from './../constants'

const initialState = null

export function productReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PRODUCT:
      return payload.product
    default:
      return state
  }
}

export default productReducer
