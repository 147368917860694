import React from 'react'
import { Breadcrumb, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hex } from 'wcag-contrast'
import cx from 'classnames'
import { Theme } from 'interfaces/Theme'

const propTypes = {
  breadcrumbs: PropTypes.array,
  theme: Theme
}

const PATH_COLOR = '#3D54CC'
const ACTIVE_COLOR = '#120340'
const CONTRAST_RATIO_THRESHOLD = 2

const Breadcrumbs = ({ breadcrumbs = [], theme }) => {
  const pathHaveContrast = () =>
    hex(theme.layout.background_color, PATH_COLOR) > CONTRAST_RATIO_THRESHOLD
  const activeHaveContrast = () =>
    hex(theme.layout.background_color, ACTIVE_COLOR) > CONTRAST_RATIO_THRESHOLD

  return (
    <div className='container-md' data-testid='breadcrumbs'>
      <Row>
        <Col>
          <Breadcrumb>
            {breadcrumbs.map((item, key) => (
              <Breadcrumb.Item
                key={key}
                href={item.path}
                active={item.active}
                className={cx({
                  'contrast-path': !pathHaveContrast(),
                  'contrast-active': !activeHaveContrast()
                })}
              >
                {item.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  )
}

Breadcrumbs.propTypes = propTypes

const mapStateToProps = ({ theme }) => ({ theme })

export default connect(mapStateToProps, null)(Breadcrumbs)
