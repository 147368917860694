import React, { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import axios from '../helpers/Api';

const propTypes = {
  documentId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired
}

const DocumentSubscription = ({ documentId, productId }) => {
  const [formState, setFormState] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')
  const { t } = useTranslation()

  const emailRegex = email => /^[\w-+.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email)

  const handleSubmit = () => {
    setSubmitting(true)
    if (emailRegex(email)) {
      const payload = {
        subscription: {
          email: email,
          document_id: documentId,
          product_id: productId
        }
      }

      axios.post('/subscribers', payload)
        .then(response => {
          setFormState('success')
          setSubmitting(false)
        }).catch(error => {
          console.log({ error })
          setFormState('already_used')
          setSubmitting(false)
        })
    } else {
      setFormState('invalid_email')
      setSubmitting(false)
    }
  }

  const handleOnChange = (event) => {
    setFormState(null)
    setEmail(event.target.value)
  }

  return (
    <Col>
      <h3>
        <strong>
          {t('document_subscriptions.subscribe_title')}
        </strong>
      </h3>

      <p>
        {t('document_subscriptions.subscribe_body')}
      </p>

      <Form.Control
        className="mb-2"
        data-testid="email-field"
        onChange={event => handleOnChange(event)}
        isInvalid={formState && formState !== 'success'}
        isValid={formState && formState === 'success'}
        type="email"
        placeholder={t('document_subscriptions.enter_email')}
      />

      {formState &&
        <Form.Control.Feedback type='invalid' data-testid="invalid-email">
          {t(`document_subscriptions.${formState}`)}
        </Form.Control.Feedback>
      }

      <Button
        disabled={isSubmitting}
        onClick={handleSubmit}
        variant="outline-primary"
        className="mb-2 btn-block"
        data-testid="submit-button">
        {t('document_subscriptions.submit')}
      </Button>

      <p className="description">
        {t('document_subscriptions.disclaimer')}
      </p>

    </Col>
  )
}

DocumentSubscription.propTypes = propTypes

export default DocumentSubscription
