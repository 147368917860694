import { SET_THEME } from './../constants'

const initialState = null

export function themeReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_THEME:
      return payload.theme
    default:
      return state
  }
}

export default themeReducer
