import { deepMerge } from 'components/shared/utils'

import bgContent from 'components/connect/helpers/i18n/content/bg.json'
import csContent from 'components/connect/helpers/i18n/content/cs.json'
import daContent from 'components/connect/helpers/i18n/content/da.json'
import deContent from 'components/connect/helpers/i18n/content/de.json'
import elContent from 'components/connect/helpers/i18n/content/el.json'
import enContent from 'components/connect/helpers/i18n/content/en.json'
import esContent from 'components/connect/helpers/i18n/content/es.json'
import etContent from 'components/connect/helpers/i18n/content/et.json'
import fiContent from 'components/connect/helpers/i18n/content/fi.json'
import frContent from 'components/connect/helpers/i18n/content/fr.json'
import gaContent from 'components/connect/helpers/i18n/content/ga.json'
import huContent from 'components/connect/helpers/i18n/content/hu.json'
import itContent from 'components/connect/helpers/i18n/content/it.json'
import jaContent from 'components/connect/helpers/i18n/content/ja.json'
import ltContent from 'components/connect/helpers/i18n/content/lt.json'
import lvContent from 'components/connect/helpers/i18n/content/lv.json'
import mtContent from 'components/connect/helpers/i18n/content/mt.json'
import nlContent from 'components/connect/helpers/i18n/content/nl.json'
import noContent from 'components/connect/helpers/i18n/content/no.json'
import plContent from 'components/connect/helpers/i18n/content/pl.json'
import ptContent from 'components/connect/helpers/i18n/content/pt.json'
import roContent from 'components/connect/helpers/i18n/content/ro.json'
import ruContent from 'components/connect/helpers/i18n/content/ru.json'
import skContent from 'components/connect/helpers/i18n/content/sk.json'
import slContent from 'components/connect/helpers/i18n/content/sl.json'
import svContent from 'components/connect/helpers/i18n/content/sv.json'
import hrContent from 'components/connect/helpers/i18n/content/hr.json'
import zhContent from 'components/connect/helpers/i18n/content/zh.json'
import arContent from 'components/connect/helpers/i18n/content/ar.json'
import trContent from 'components/connect/helpers/i18n/content/tr.json'
import bgContinent from 'components/connect/helpers/i18n/continents/bg.json'
import csContinent from 'components/connect/helpers/i18n/continents/cs.json'
import daContinent from 'components/connect/helpers/i18n/continents/da.json'
import deContinent from 'components/connect/helpers/i18n/continents/de.json'
import elContinent from 'components/connect/helpers/i18n/continents/el.json'
import enContinent from 'components/connect/helpers/i18n/continents/en.json'
import esContinent from 'components/connect/helpers/i18n/continents/es.json'
import etContinent from 'components/connect/helpers/i18n/continents/et.json'
import fiContinent from 'components/connect/helpers/i18n/continents/fi.json'
import frContinent from 'components/connect/helpers/i18n/continents/fr.json'
import gaContinent from 'components/connect/helpers/i18n/continents/ga.json'
import huContinent from 'components/connect/helpers/i18n/continents/hu.json'
import itContinent from 'components/connect/helpers/i18n/continents/it.json'
import jaContinent from 'components/connect/helpers/i18n/continents/ja.json'
import ltContinent from 'components/connect/helpers/i18n/continents/lt.json'
import lvContinent from 'components/connect/helpers/i18n/continents/lv.json'
import mtContinent from 'components/connect/helpers/i18n/continents/mt.json'
import nlContinent from 'components/connect/helpers/i18n/continents/nl.json'
import noContinent from 'components/connect/helpers/i18n/continents/no.json'
import plContinent from 'components/connect/helpers/i18n/continents/pl.json'
import ptContinent from 'components/connect/helpers/i18n/continents/pt.json'
import roContinent from 'components/connect/helpers/i18n/continents/ro.json'
import ruContinent from 'components/connect/helpers/i18n/continents/ru.json'
import skContinent from 'components/connect/helpers/i18n/continents/sk.json'
import slContinent from 'components/connect/helpers/i18n/continents/sl.json'
import svContinent from 'components/connect/helpers/i18n/continents/sv.json'
import hrContinent from 'components/connect/helpers/i18n/continents/hr.json'
import zhContinent from 'components/connect/helpers/i18n/continents/zh.json'
import arContinent from 'components/connect/helpers/i18n/continents/ar.json'
import trContinent from 'components/connect/helpers/i18n/continents/tr.json'

const regionsTranslations = {
  bg: deepMerge(bgContent, bgContinent),
  cs: deepMerge(csContent, csContinent),
  da: deepMerge(daContent, daContinent),
  de: deepMerge(deContent, deContinent),
  el: deepMerge(elContent, elContinent),
  en: deepMerge(enContent, enContinent),
  es: deepMerge(esContent, esContinent),
  et: deepMerge(etContent, etContinent),
  fi: deepMerge(fiContent, fiContinent),
  fr: deepMerge(frContent, frContinent),
  ga: deepMerge(gaContent, gaContinent),
  hu: deepMerge(huContent, huContinent),
  it: deepMerge(itContent, itContinent),
  ja: deepMerge(jaContent, jaContinent),
  lt: deepMerge(ltContent, ltContinent),
  lv: deepMerge(lvContent, lvContinent),
  mt: deepMerge(mtContent, mtContinent),
  nl: deepMerge(nlContent, nlContinent),
  no: deepMerge(noContent, noContinent),
  pl: deepMerge(plContent, plContinent),
  pt: deepMerge(ptContent, ptContinent),
  ro: deepMerge(roContent, roContinent),
  ru: deepMerge(ruContent, ruContinent),
  sk: deepMerge(skContent, skContinent),
  sl: deepMerge(slContent, slContinent),
  sv: deepMerge(svContent, svContinent),
  hr: deepMerge(hrContent, hrContinent),
  zh: deepMerge(zhContent, zhContinent),
  ar: deepMerge(arContent, arContinent),
  tr: deepMerge(trContent, trContinent)
}
export default regionsTranslations
