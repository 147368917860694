import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'

import { HCP_COOKIE_NAME } from 'components/connect/helpers/Constants'
import { toBoolean } from 'components/shared/utils'

const HEX_APLHA_80 = 'cc'

const alphasizeColor = (color) => `${color}${HEX_APLHA_80}`

const propTypes = {
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
}

const ModalStyled = styled(Modal)`
  background-color: ${(props) => props.disclaimer.background_color};
`

const ButtonStyled = styled.button`
  background-color: ${(props) => props.disclaimer.button_background_color};
  color: ${(props) => props.disclaimer.button_text_color};

  &:hover {
    background-color: ${(props) => props.disclaimer.button_background_color};
    color: ${(props) => props.disclaimer.button_text_color};

    &:not(:disabled) {
      background-color: ${(props) =>
        alphasizeColor(props.disclaimer.button_background_color)};
      color: ${(props) => alphasizeColor(props.disclaimer.button_text_color)};
    }

    &.disabled {
      background-color: ${(props) => props.disclaimer.button_background_color};
      color: ${(props) => props.disclaimer.button_text_color};
    }
  }
`

const HeaderRichtextStyled = styled.h2`
  color: ${(props) => props.disclaimer.text_color};
`

const RichtextStyled = styled.div`
  color: ${(props) => props.disclaimer.text_color};
`

const FormCheckStyled = styled(Form.Check)`
  label {
    color: ${(props) => props.disclaimer.text_color};
  }
`

const FooterButtonStyled = styled.a`
  color: ${(props) => props.disclaimer.text_color};
`

const Disclaimer = ({ theme, t, show, setShow }) => {
  const [agreed, setAgreed] = useState(false)
  const [pulse, setPulse] = useState(false)

  useEffect(() => {
    setShow(toBoolean(theme.disclaimer.show))
  }, [theme.disclaimer.show])

  const handleClose = () => {
    if (!agreed) {
      setPulse(true)
    } else {
      document.cookie = `${HCP_COOKIE_NAME}=true; expires=${moment()
        .add(30, 'm')
        .utc()}`
      setShow(false)
    }
  }
  const handleAgreed = () => setAgreed(!agreed)

  const Footer = () => (
    <Form.Group className='no-healthcare-container'>
      <FooterButtonStyled
        disclaimer={theme.disclaimer}
        href={theme.disclaimer?.url}
        target='_blank'
        rel='noopener noreferrer'
        className='no-healthcare'
        data-testid='disclaimer-footer-button'
      >
        {t('disclaimer.redirect')}
      </FooterButtonStyled>
    </Form.Group>
  )

  return (
    <ModalStyled
      disclaimer={theme.disclaimer}
      show={show}
      onHide={handleClose}
      size='xl'
      backdrop='static'
      backdropClassName='disclaimer-backdrop'
      dialogClassName='disclaimer-dialog'
      data-testid='disclaimer-modal'
      centered
    >
      <Modal.Body>
        <Row>
          <Col lg={{ span: 6, offset: 6 }} className='float-right'>
            <HeaderRichtextStyled
              disclaimer={theme.disclaimer}
              data-testid='disclaimer-richtext-heading'
            >
              <Trans
                i18nKey='disclaimer.heading'
                company={theme.organisation_name}
              >
                Welcome to the <div>eIFU System</div>
              </Trans>
            </HeaderRichtextStyled>

            <RichtextStyled
              disclaimer={theme.disclaimer}
              data-testid='disclaimer-richtext-body'
            >
              {t('disclaimer.body')}
            </RichtextStyled>

            <Form.Group
              className={cx({ pulse })}
              onAnimationEnd={() => setPulse(false)}
            >
              <FormCheckStyled
                disclaimer={theme.disclaimer}
                type='checkbox'
                label={t('disclaimer.checkbox')}
                onClick={handleAgreed}
                id='agreedCheckbox'
                data-testid='disclaimer-checkbox'
              />
            </Form.Group>

            <ButtonStyled
              disclaimer={theme.disclaimer}
              onClick={handleClose}
              data-testid='disclaimer-button'
              className={cx('btn btn-lg', !agreed && 'disabled')}
            >
              {t('disclaimer.button')}
            </ButtonStyled>
            {toBoolean(theme.disclaimer.activate_url) && <Footer />}
          </Col>
        </Row>
      </Modal.Body>
    </ModalStyled>
  )
}

Disclaimer.propTypes = propTypes

export default withTranslation()(Disclaimer)
