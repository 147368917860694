import React from 'react'
import { Info } from '@styled-icons/material'
import cx from 'classnames'
import PropTypes from 'prop-types'

const propTypes = {
  countrySelection: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  color: PropTypes.string
};

const InfoIcon = ({ countrySelection, handleClick }) => (
  <div
    className={cx(
      'about-container',
      !countrySelection && 'no-country-selection'
    )}
    data-testid='about-container'
  >
    <div
      className='about-button'
      onClick={handleClick}
      data-testid='about-button'
    >
      <span>
        <span
          className='about-button-icon align-middle'
          data-testid='about-button-icon'
        >
          <Info size='24' />
        </span>
      </span>
    </div>
  </div>
)

InfoIcon.propTypes = propTypes;

export default InfoIcon;
