import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { compact } from 'lodash'
import PropTypes from 'prop-types'

import RegionCard from './RegionCard'

const propTypes = {
  onSelect: PropTypes.func,
  regions: PropTypes.array,
  locale: PropTypes.string,
  defaultActiveKey: PropTypes.string
}

const CountrySelector = ({
  onSelect,
  regions,
  locale,
  defaultActiveKey = '0'
}) => {
  const [activeRegion, setActiveRegion] = useState(null)

  const hasCountries = (region) => !!compact(region.countries).length

  return (
    <div className='country-selector' data-testid='country-selector'>
      <Accordion defaultActiveKey={defaultActiveKey}>
        {regions.map(
          (region) =>
            hasCountries(region) && (
              <RegionCard
                key={region.name}
                onSelect={onSelect}
                region={region}
                locale={locale}
                activeRegion={activeRegion}
                setActiveRegion={setActiveRegion}
              />
            )
        )}
      </Accordion>
    </div>
  )
}

CountrySelector.propTypes = propTypes

export default CountrySelector
