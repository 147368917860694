import axios from "axios";

export const getMetaValue = (name) => {
  const element = document.querySelector(`meta[name="${name}"]`);
  return element ? element.getAttribute('content') : '';
};

const baseURL = (() => {
  if (process.env.RAILS_ENV === 'test') {
    return `http://${getMetaValue('api-url')}:53000`;
  } else if (process.env.RAILS_ENV === 'production') {
    return `https://${getMetaValue('api-url')}`;
  } else {
    return `http://${getMetaValue('api-url')}:3000`;
  }
})();

const api = axios.create({
  baseURL: baseURL,
});

export default api;
