import { SET_ORGANISATION } from './../constants'

const initialState = null

export function organisationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ORGANISATION:
      return payload.organisation
    default:
      return state
  }
}

export default organisationReducer
