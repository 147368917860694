import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { isIE } from 'react-device-detect'
import cx from 'classnames'

import NotificationHeader from './NotificationHeader'
import NotificationBody from './NotificationBody'
import NotificationFooter from './NotificationFooter'

const propTypes = {
  notifications: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
}

const Notification = ({ notifications, show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    backdrop='static'
    size='lg'
    data-testid='notification'
    scrollable
    centered
    dialogClassName={cx('connect-dialog notification-dialog', {
      'modal-content-ie': isIE
    })}
  >
    <NotificationHeader notifications={notifications} />
    <NotificationBody notifications={notifications} />
    <NotificationFooter notifications={notifications} onHide={onHide} />
  </Modal>
)

Notification.propTypes = propTypes

export default Notification
