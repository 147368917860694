import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CountrySelector from './CountrySelector'

const propTypes = {
  regions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
}

const CountrySelect = ({ regions, onSelect, locale }) => {
  const { t } = useTranslation()
  return (
    <Container className='region-container'>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className='region-title' data-testid='region-title'>
            {t('region.title')}
          </h2>
          <p className='region-description' data-testid='region-description'>
            {t('region.description')}
          </p>
          <CountrySelector
            onSelect={onSelect}
            regions={regions}
            locale={locale}
          />
        </Col>
      </Row>
    </Container>
  )
}

CountrySelect.propTypes = propTypes

export default CountrySelect
